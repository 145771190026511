<template>
  <HeaderCard
    :image="props.image"
    :float="props.float"
    divider
  >
    <template #header>
      <h1 class="subpixel-antialiased h0 mt-24">
        {{ props.heading }}
      </h1>
    </template>

    <template #default="{ hasFloat }">
      <p
        :data-float="hasFloat"
        class="text-slate-900 col-start-1 ml-8 laptop:ml-16 mr-8 laptop:mr-4 mt-6 laptop:data-[float=true]:mr-[28em]"
      >
        {{ props.paragraph }}
      </p>
      <div class="mb-8">
        <NuxtLink
          v-if="props.to"
          :to="props.to"
          :external="isExternal"
          :target="isExternal ? '_blank' : ''"
          class="bg-primary hover:bg-primary-highlighted active:bg-primary-selected text-white
          text-center rounded py-2 px-4 col-start-1 mt-8 mr-auto ml-8 laptop:ml-16 w-fit
          flex flex-row gap-3 font-semibold"
        >
          <font-awesome-icon
            v-if="props.icon"
            :icon="props.icon"
            class="text-[1.2em]"
          />
          <span>{{ props.label }}</span>
        </NuxtLink>
      </div>
    </template>
  </HeaderCard>
</template>

<script setup lang="ts">
import type { Icon, Image } from "~/utils/types";
import type { RouteLocationRaw } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps<{
  heading: string
  paragraph: string
  // link
  label?: string
  icon?: Icon
  to?: RouteLocationRaw
  external?: boolean
  // resources
  image: Image
  float?: Image
}>()

const isExternal = computed(() => props.external || (typeof props.to == "string" && props.to.startsWith("http")))
</script>
